import { isProduction } from "~/services/env";

export default defineNuxtPlugin({
  name: "sentry-plugin",
  enforce: "post",
  async setup({ vueApp: app }) {
    const router = app.$nuxt.$router;

    const PRODUCTION = isProduction();

    let Sentry: typeof import("@sentry/browser");
    Sentry = await import("@sentry/browser");
    Sentry.init({
      dsn: PRODUCTION
        ? "https://e53f3879047c20cd4f627d8b6008be9c@o1128224.ingest.us.sentry.io/4507984472768512"
        : "https://25b56124d7fceb64a0a61e218b5387fd@o1128224.ingest.us.sentry.io/4507984655024128",
      integrations: [
        Sentry.browserTracingIntegration({
          // router,
          // routeLabel: "path",
          shouldCreateSpanForRequest: (url) => {
            // Do not create spans for outgoing requests to a `/health/` endpoint
            return !url.match(/\/ping\/?$/);
          },
        }),
        Sentry.replayIntegration(),
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // https://soga-node-api.sonic.game
      // https://api-staging.mirrorworld.fun
      tracePropagationTargets: PRODUCTION
        ? [
            /^https:\/\/node\.sonic\.game/,
            /^https:\/\/api\.mirrorworld\.fun/,
            /^https:\/\/soga-node-api\.sonic\.game/,
          ]
        : [
            "localhost",
            /^https:\/\/node-staging\.sonic\.game/,
            /^https:\/\/api-staging\.mirrorworld\.fun/,
            /^https:\/\/soga-node-staging-api\.sonic\.game/,
          ],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    console.log("Sentry initialized...");
    // @ts-ignore
    window.Sentry = Sentry;

    return {
      provide: {
        Sentry,
      },
    };
  },
});
