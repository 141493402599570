<template>
  <NuxtLayout>
    <NuxtPage />
    <Toaster />
  </NuxtLayout>
  <div vaul-drawer-wrapper />
</template>

<script setup lang="ts">
import { useClusterHealth } from "~/services/queries/use-cluster-health";
import { Toaster } from "@/components/ui/sonner";
useClusterHealth();
</script>
