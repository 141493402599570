import * as walletAdapters from "@solana/wallet-adapter-wallets";
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { canUseDOM } from "~/lib/dom";
import { useStorage } from "@vueuse/core";
import { truncateMiddle } from "~/lib/string";
import { createPinia, defineStore } from "pinia";
import { wait } from "~/lib/timers";
import * as naclutil from "tweetnacl-util";
import { cluster } from "~/services/cluster";
import type { AuthenticatedUser } from "~/services/types";
import type { ResponseType } from "~/services/api.keys";
import type { BackpackWalletAdapter } from "~/lib/backpack";
import type { OKXWalletAdapter } from "~/lib/okx";
import { api } from "~/services/api";

const encodeBase64 = naclutil.encodeBase64;
export const pinia = createPinia();

let WHITELIST_KEY = `whitelist_eligibility`;

export type WalletAdapter =
  | PhantomWalletAdapter
  | OKXWalletAdapter
  | SolflareWalletAdapter
  | BackpackWalletAdapter;

export interface WalletState {
  wallet: WalletAdapter | null;
  wallets: WalletAdapter[];
  lastConnectedWalletName?: WalletAdapter["name"];
  identityToken?: string;
}

export const walletStorage = useStorage<Omit<WalletState, "wallets">>(
  "wallet",
  {
    wallet: null,
  }
);

export const _useWalletsStore = defineStore("wallet-store", () => {
  const { $wallets } = useNuxtApp();

  const _wallet = ref<WalletAdapter | null>(null);
  const _wallets = ref<WalletAdapter[]>([]);
  const lastConnectedWalletName = ref<WalletAdapter["name"] | undefined>(
    undefined
  );
  const user = ref<AuthenticatedUser | null>(null);

  watch(
    () => $wallets,
    (value) => {
      console.debug("wallets changed ----", value);
      if (!canUseDOM() || !value) return;
      _wallets.value = value;
    },
    {
      immediate: true,
    }
  );

  const wallets = computed(() => _wallets.value);
  const wallet = computed(() => _wallet.value);
  const publicKey = computed(() => wallet.value?.publicKey);

  const shortAddress = computed(() => {
    return (
      !!wallet.value &&
      !!wallet.value.publicKey &&
      truncateMiddle(wallet.value.publicKey.toBase58(), 8)
    );
  });
  const isAuthenticated = computed(() => !!wallet.value);
  const isWalletConnected = computed(() => !!wallet.value);

  const connected = computed(() => {
    return !!isWalletConnected.value || !!isAuthenticated.value;
  });

  const auth_token = computed(() => {
    return walletStorage.value.identityToken;
  });

  const setWallet = (value: WalletAdapter | null) => {
    _wallet.value = value;
    console.debug("setting wallet", _wallet.value);
  };

  const setWallets = (value: WalletAdapter[]) => {
    if (!canUseDOM()) return;
    _wallets.value = value;
    console.debug("setting wallets", _wallets.value);
  };

  const noop = () => Promise.resolve();

  async function fetchUser() {
    await noop();
    user.value = {
      wallet: _wallet.value?.publicKey?.toBase58()!,
    };
    return user.value;
  }

  async function connectWallet(wallet: WalletAdapter) {
    if (!canUseDOM()) return;
    await wallet.connect();
    await wait(300);
    setWallet(wallet);
    _wallet.value = wallet;
    walletStorage.value.lastConnectedWalletName = wallet.name;
  }

  const authenticateWallet = async (wallet: WalletAdapter) => {
    await connectWallet(wallet);

    // Request Wallet Challenge
    const encodedWalletAddress = encodeBase64(wallet.publicKey!.toBytes());
    const { data: message } = await api.get("/challenge", {
      params: {
        wallet: wallet.publicKey!.toBase58(),
        sol_address_encoded: encodedWalletAddress,
      },
    });

    const encodedMessage = new TextEncoder().encode(message);

    if (!wallet.publicKey) throw new Error("Wallet not connected!");
    if (!wallet.signMessage)
      throw new Error("Wallet does not support message signing!");

    const signature = await wallet.signMessage(encodedMessage);

    const nacl = await import("tweetnacl");
    const verifiedMessage = nacl.sign.detached.verify(
      new TextEncoder().encode(message),
      signature,
      wallet.publicKey!.toBytes()
    );

    if (!verifiedMessage) {
      throw new Error(
        "Invalid signature. Please sign this transaction with the wallet you are connecting with."
      );
    }

    const authenticatedUser = await fetchUser();
    if (authenticatedUser) {
      user.value = authenticatedUser;
    }

    // Set Node Sale whitelist sale eligibility state
    const eligibilityState = `whitelist:${wallet.publicKey!.toBase58()}:false`;
    if (canUseDOM()) localStorage.setItem(WHITELIST_KEY, eligibilityState);

    location.reload();

    return user.value;
  };

  const disconnect = async () => {
    if (!canUseDOM()) return;
    if (!_wallet.value) return;
    await _wallet.value.disconnect();

    walletStorage.value.identityToken = undefined;

    const result = await cluster
      .post("/auth/logout", {
        headers: {
          Authorization: `Bearer ${walletStorage.value.identityToken}`,
        },
      })
      .catch((e) => {
        console.error("Error fetching user", e);
        return null;
      });

    console.debug("Logout message", result);

    user.value = null;
    setWallet(null);
    walletStorage.value.lastConnectedWalletName = undefined;

    if (canUseDOM()) localStorage.removeItem(WHITELIST_KEY);

    location.href = "/";
  };

  return {
    _wallet,
    wallet,
    wallets,
    isAuthenticated,
    isWalletConnected,
    lastConnectedWalletName,
    setWallet,
    setWallets,
    connectWallet,
    authenticateWallet,
    disconnect,
    shortAddress,
    connected,
    publicKey,
    auth_token,
    user,
    fetchUser,
  };
});

export function useWalletsStore() {
  return _useWalletsStore(pinia);
}
