import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  // CoinbaseWalletAdapter,
  // NightlyWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { WalletReadyState } from "@solana/wallet-adapter-base";
import { BackpackWalletAdapter } from "~/lib/backpack";
// import { NightlyWalletAdapter } from "~/lib/nightly";
import type { AuthenticatedUser } from "~/services/types";
import { _useWalletsStore, walletStorage } from "~/stores/wallets";
import { pinia } from "~/stores/wallets";
import { OKXWalletAdapter } from "~/lib/okx";

export default defineNuxtPlugin({
  name: "wallets-plugin",
  enforce: "post",
  async setup({ vueApp }) {
    vueApp.use(pinia);

    const {
      $subscribe,
      connectWallet,
      connected,
      fetchUser,
      setWallet,
      setWallets,
      disconnect,
    } = _useWalletsStore(pinia);
    const wallets = [
      new PhantomWalletAdapter(),
      new OKXWalletAdapter(),
      new SolflareWalletAdapter(),
      // new NightlyWalletAdapter(),
      new BackpackWalletAdapter(),
      // new CoinbaseWalletAdapter(),
    ];

    setWallets(wallets);

    $subscribe((_, state) => {
      walletStorage.value.lastConnectedWalletName = state?._wallet?.name;
    });

    const lastConnectedWalletName = walletStorage.value.lastConnectedWalletName;
    const identityToken = walletStorage.value.identityToken;

    let user: AuthenticatedUser | undefined;
    if (identityToken) {
      console.debug("Fetching user from identity token");
      user = await fetchUser();
    }

    if (lastConnectedWalletName && !connected) {
      const wallet = wallets.find(
        (wallet) => wallet.name === lastConnectedWalletName
      );
      if (wallet && wallet.readyState === WalletReadyState.Installed) {
        console.debug("Auto connecting previously connected wallet", wallet);
        await connectWallet(wallet);
        setWallet(wallet);
      }
    }

    let intervalId: ReturnType<typeof setInterval> | undefined;
    // Poll connected solana wallets for changes
    // function pollForWalletChanges(interval = 300) {
    //   clearInterval(intervalId);
    //   intervalId = setInterval(async () => {
    //     console.debug("polling for wallet changes");
    //     if (
    //       window.solana?._publicKey?.toBase58() !==
    //       wallet?.publicKey?.toBase58()
    //     ) {
    //       await disconnect();
    //       // await connect(window.solana);
    //     }
    //   }, 300);

    //   return intervalId;
    // }

    // watch(
    //   () => wallet?.publicKey?.toBase58(),
    //   async (newValue) => {
    //     if (!connected) return;
    //     console.debug("wallet changed. Resume polling", newValue);
    //     pollForWalletChanges();
    //   },
    //   {
    //     immediate: true,
    //   }
    // );

    return {
      provide: {
        wallets,
      },
    };
  },
});
