export const keys = {
  health: () => ["service:health"],
  validateKey: (code: string, wallet?: string) => [
    `validate:code:${wallet}:${code}`,
  ],
  info: () => ["nodesale:info"],
  purchaseInfo: (wallet: string) => ["node:info", wallet],
  purchasePrices: (
    quantity: number,
    wallet: string,
    invitationCode: string
  ) => [`node:purchase:price:w:${wallet}:q:${quantity}:c:${invitationCode}`],
  buildPurchaseTransaction: () => ["node:build-tx"],
  airdrop: () => ["node:airdrop"],
  airdropStatus: (order_id: string) => ["node:airdrop:status", order_id],
  orderStatus: (order_id: string) => ["order:status", order_id],
  referralRewards: (wallet: string) => ["referral:rewards", wallet],
  sonicRewards: (wallet: string) => ["referral:sonic:rewards", wallet],
  sonicRewardsInfo: (wallet: string) => ["referral:sonic:rewards:info", wallet],
  whitelistTiers: (wallet?: string) => ["whitelist:tiers", wallet],
  purchaseHistory: (wallet: string, page: number, page_size: number) => [
    `history:purchase:${wallet}:${page}:${page_size}`,
  ],
};

export type ResponseType<T> = {
  code: number;
  data: T;
  message: string;
};
