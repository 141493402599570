import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as historyccFtdcgOm1Meta } from "/vercel/path0/pages/history.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as referralsicwDbnmRCcMeta } from "/vercel/path0/pages/referrals.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "history",
    path: "/history",
    component: () => import("/vercel/path0/pages/history.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "referrals",
    path: "/referrals",
    meta: referralsicwDbnmRCcMeta || {},
    component: () => import("/vercel/path0/pages/referrals.vue").then(m => m.default || m)
  }
]