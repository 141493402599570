import { useQuery } from "@tanstack/vue-query";
import { keys } from "../api.keys";
import { api } from "../api";

export function useClusterHealth() {
  return useQuery({
    queryKey: keys.health(),
    queryFn: () => api.get("/ping"),
    refetchInterval: 60000,
  });
}
