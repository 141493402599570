import ttq from "tiktok-pixel";
import { truncateMiddle } from "~/lib/string";

import { defineNuxtPlugin, useRequestHeaders } from "#app";

export default defineNuxtPlugin(() => {
  void ttq.init("COKAMQ3C77U0UU2BU88G");
  console.debug(
    "tiktok pixel initialized:",
    truncateMiddle("COKAMQ3C77U0UU2BU88G", 16)
  );

  return {
    provide: {
      ttq,
    },
  };
});
